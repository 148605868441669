import { store, USER } from 'util/store';

import { generateQueryParams } from 'services/urlService';
import { showError, isDefined, getFromFragment, sleep } from 'util/index';
import { attemptSilentAuth, isSilentAuthInProgress } from './authenticate';

const MAX_RETRIES = 3; // max number of times api is auto retried

export const get = _get;

export const post = _post;

export const del = _delete;

export const put = _put;

export function getProfileVisibility(queryParams) {
	return get(`/server/rest/profile/checkVisibility`, queryParams);
}

export function getUser() {
	return _get(`/server/rest/user`, undefined, {});
}

export function getUserInfo(filter) {
	return _get(`/server/rest/userInfo`, { filter });
}

export function getUserPreference(groupId, variableKey) {
	return _get(`/server/rest/userPreference`, { groupId, variableKey });
}

export function setUserPreference(groupId, variableKey, value) {
	if (variableKey) {
		return _post(`/server/rest/userPreference?groupId=${groupId}&variableKey=${variableKey}`, { value });
	}
	return _post(`/server/rest/userPreference?groupId=${groupId}`, { value });
}

export function getGames(location, queryParams) {
	return _get(`/server/rest/${location}/games`, queryParams);
}

export function getCategories(location, queryParams) {
	return _get(`/server/rest/${location}/categories`, queryParams);
}

export function getBadgeCount(queryParams) {
	return _get('/server/rest/ownedBadgeCount', queryParams);
}

export function getChallenges(location, display, queryParams) {
	return _get(`/server/rest/${location}/challenges/${display}`, queryParams);
}

export function getEvent(challengeId, queryParams) {
	return _get(`/server/rest/event/get/${challengeId}`, queryParams, {});
}

export function getEventSpotlight(queryParams) {
	return _get(`/server/rest/event/spotlight`, queryParams, {});
}

export function getProfile(queryParams) {
	return _get('/server/rest/profile', queryParams);
}

export function setProfile(settings = {}) {
	return _post('/server/rest/profile', settings);
}

export function getOnboarding() {
	return _get(`/server/rest/onboarding`);
}

export function getAvatar(queryParams) {
	return _get('/server/rest/avatar', queryParams);
}

export function getSubsOfferPopup(isPaidUA) {
	return get('/server/rest/offers/popup/subs', { isPaidUA }, {});
}

export function closeOfferPopup(offerGroupId, offerId, body) {
	if (offerId) {
		return _post(`/server/rest/userPreference?groupId=${offerGroupId}&variableKey=${offerId}`, body);
	}
	return _post(`/server/rest/userPreference?groupId=${offerGroupId}`, body);
}

export function setSubscriptionDate(body) {
	return _post(`/server/rest/userPreference?groupId=SUBSCRIPTION_DATE`, body);
}

export function getSubscriptionDate() {
	return _get(`/server/rest/userPreference?groupId=SUBSCRIPTION_DATE`);
}


export function getSocialScreenNameResults(screenName, body) {
	return _post(`/server/rest/social/screenName?screenName=${screenName}`, body);
}

export function closeClubRewardsPopUp() {
	return _post(`/server/rest/clubReward/close`);
}

export function getGemBalance() {
	return get(`/server/rest/gems/balance`);
}
export function getClubRewards() {
	return get('/server/rest/clubReward/progress');
}

export function getUserType() {
	return get(`/server/rest/userType`);
}
// TODO: remove after challengeQueueRevamp merged to main
export function getChallengeRecommendation(gameCode) {
	// return same response that /challenge/upsell.do returns
	// this should return a single challenge object
	return Promise.resolve();
}


// ---------------------------------------- private functions ----------------------------------------

function _status(response) {
	if (response.status >= 200 && response.status < 300) {
		return Promise.resolve(response);
	}
	return Promise.reject(response);
}

function _json(response) {
	return response.json().catch(() => ({}));
}

function _post(url, body, defaultResponse, retry, queryParamsObject, silentAuth) {
	body = _clearBody(body);
	url = queryParamsObject ? url + generateQueryParams(queryParamsObject) : url;
	return _request('post', url, body, defaultResponse, retry, silentAuth);
}

function _get(url, queryParamsObject, defaultResponse, retry, silentAuth) {
	url = queryParamsObject ? url + generateQueryParams(queryParamsObject) : url;
	return _request('get', url, undefined, defaultResponse, retry, silentAuth);
}

function _put(url, body, defaultResponse, retry, queryParamsObject) {
	body = _clearBody(body);
	url = queryParamsObject ? url + generateQueryParams(queryParamsObject) : url;
	return _request('put', url, body, defaultResponse, retry);
}

function _delete(url, defaultResponse, retry, queryParamsObject) {
	url = queryParamsObject ? url + generateQueryParams(queryParamsObject) : url;
	return _request('delete', url, undefined, defaultResponse, retry);
}

async function _request(method, url, body, defaultResponse = {}, retries = MAX_RETRIES, silentAuth = true) {
	if (isSilentAuthInProgress()) {
		return new Promise(() => {}); // return a promise that never resolves to avoid further client errors
	}
	let { [USER]: { accessToken } = {} } = store.getState();
	if (!accessToken) {
		accessToken = getFromFragment('accessToken');
	}
	let opt = {
		method,
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		redirect: 'manual'
	};
	if (accessToken) {
		opt.headers['x-pogo-access-token'] = accessToken;
		opt.headers.accessToken = accessToken;
	}
	if (body) {
		opt.body = JSON.stringify(body);
	}
	try {
		let response = await fetch(url, opt);
		// if access token has changed its returned in the header
		const accessToken = response?.headers?.get('x-pogo-access-token');
		response = await _status(response);
		response = await _json(response);
		if (accessToken) {
			store.setState({ [USER]: { ...store.getState()[USER], accessToken } });
		}
		return response;
	}
	catch (error) {
		if (error.type === 'opaqueredirect') { // in case user is outside VPN, all API get redirected to okta
			window.location.href = '/server/auth';
		}
		if (!error.status) {
			if (retries) {
				await sleep(Math.pow(2, MAX_RETRIES - retries + 1) * 1000); // exponential backoff algorithm
				return await _request(method, url, body, defaultResponse, retries - 1);
			}
			let restError = new Error(`rest api ${url} failed without a status - ${error.message}`);
			restError.status = 500;
			restError.type = 'restApi';
			throw restError;
		}
		else if (error.status === 401 && silentAuth) {
			let success = await attemptSilentAuth(error);
			if (success) {
				return new Promise(() => {}); // return a promise that never resolves to avoid further client errors
			}
			throw error;
		}
		else if (error.status === 404) {
			return defaultResponse;
		}
		else if (error.status === 418) { // 418 is the status code returned by spike for service unavailable
			showError(418);
			return new Promise(() => { }); // return a promise that never resolves to avoid further client errors
		}
		else {
			return _json(error).then((errorObject = {}) => {
				let restError = new Error(`rest api ${url.split('?')[0]} failed with status ${error.status} - 
					${errorObject.message || errorObject.error || error.message}`);
				restError.status = error.status;
				restError.type = 'restApi';
				restError.error = errorObject.error;
				restError.reason = errorObject.message; // TODO: will remove shortly since restError.message is already available
				restError.message = errorObject.message;
				throw restError;
			});
		}
	}
}

function _clearBody(body) {
	for (let key in body) {
		if (isDefined(body[key])) {
			continue;
		}
		else {
			delete body[key];
		}
	}
	return body;
}
